<ngx-loading [show]="isLoading"
             [config]="{animationType: ngxLoadingAnimationTypes.cubeGrid, primaryColour: '#0000ff', secondaryColour: '#00f', backdropBorderRadius: '3px'}"
             [template]="loadingTemplate"></ngx-loading>

<ng-template #loadingTemplate>
  <div class="custom-loader">
    <div class="spinner"></div>
    <p>Loading...</p>
  </div>
</ng-template>

<div style="width: 100%; display: flex; flex-direction: row" cols="4" rowHeight="1rem">
  <div>
    <mat-card-header style="font-size: 2rem">Airline Promotions</mat-card-header>
  </div>
  <div class="btn-actions-pane-right p-2">
    <button class="float-end" mat-raised-button color="primary" (click)="goToCreateNewAirlinePromotion()">Create
      New
    </button>
  </div>
</div>
<mat-card>
  <!--  <mat-form-field style="width: 30%">-->
  <!--    <mat-label>Filter</mat-label>-->
  <!--    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>-->
  <!--  </mat-form-field>-->
  <div class="row p-4">
    <div class="col-md-2 row">
      <mat-form-field appearance="standard" style="width: 80%">
        <mat-label>Airline</mat-label>
        <mat-select [(ngModel)]="airline">
          <mat-option value="null">ALL</mat-option>
          <mat-option *ngFor="let airline of airlines" [value]="airline.id">
            {{airline.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 row">
      <mat-form-field appearance="standard" style="width: 80%">
        <mat-label>Product</mat-label>
        <mat-select [(ngModel)]="productType" (selectionChange)="onProductTypeChange()">
          <mat-option value="null">ALL</mat-option>
          <mat-option *ngFor="let type of productTypes" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2 row">
      <mat-form-field appearance="standard" style="width: 80%">
        <mat-label>Consignment Type</mat-label>
        <mat-select [(ngModel)]="consignmentType" (selectionChange)="onCNTypeChange()">
          <mat-option value="null">ALL</mat-option>
          <mat-option *ngFor="let type of cnTypes" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2 row">
      <mat-form-field appearance="standard" style="width: 80%">
        <mat-label>Consignment Subtype</mat-label>
        <mat-select [(ngModel)]="consignmentSubtype">
          <mat-option value="null">ALL</mat-option>
          <mat-option *ngFor="let subType of cnSubtypes" [value]="subType.id">
            {{ subType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2 row">
      <mat-form-field appearance="standard" style="width: 80%">
        <mat-label>Origin</mat-label>
        <mat-select [(ngModel)]="origin">
          <mat-option value="null">ALL</mat-option>
          <mat-option *ngFor="let origin of originAirports" [value]="origin.id">
            {{origin.city.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 row">
      <mat-form-field appearance="standard" style="width: 80%">
        <mat-label>Destination</mat-label>
        <mat-select [(ngModel)]="destination">
          <mat-option value="null">ALL</mat-option>
          <mat-option *ngFor="let destination of destinationAirports" [value]="destination.id">
            {{destination.city.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2 row">
      <mat-form-field appearance="standard">
        <input matInput [matDatepicker]="fromDatePicker" placeholder="From Date" [(ngModel)]="validFrom">
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-2 row">
      <mat-form-field appearance="standard">
        <input matInput [matDatepicker]="toDatePicker" placeholder="To Date" [(ngModel)]="validTo">
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-2 col-md-1  row m-1">
      <button class="btn btn-primary m-auto"
        (click)="getAllAilinePromotions(productType !== 'null' ? productType : null, consignmentType !== 'null' ? consignmentType : null, 
        consignmentSubtype !== 'null' ? consignmentSubtype : null, airline !== 'null' ? airline : null, origin !== 'null' ? origin : null, destination !== 'null' ? destination : null, validFrom, 
        validTo)">Find</button>
    </div>
    <div class="col-lg-2 col-md-1  row m-1">
      <button class="btn btn-secondary m-auto" (click)="onReset()">Reset</button>

    </div>
  </div>

  <ng-container *ngIf="airlinePromotionsList.data.length > 0; else noRecordsMessage">

<!--    <div class="mat-elevation-z8">-->
      <mat-table [dataSource]="airlinePromotionsList" style="width: 100%" matSort (matSortChange)="sortData($event)">

        <!-- S.No. Column -->
        <ng-container matColumnDef="S.No.">
          <mat-header-cell *matHeaderCellDef mat-sort-header> S.No.</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index"> {{ paginator?.pageIndex * paginator?.pageSize + i + 1 }}
          </mat-cell>
        </ng-container>

        <!-- Airline Column -->
        <ng-container matColumnDef="airline">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Airline</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.airline }}</mat-cell>
        </ng-container>

        <!-- Flights Column -->
        <ng-container matColumnDef="flights">
          <mat-header-cell *matHeaderCellDef> Flights</mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.flights"> {{ element.flights | slice:0:13 }}
            {{ element.flights.length > 13 ? "..." : "" }}
          </mat-cell>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="product">
          <mat-header-cell *matHeaderCellDef> Product</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.productType ? element.productType : "All" }}
          </mat-cell>
        </ng-container>

        <!-- CN Type Column -->
        <ng-container matColumnDef="cnType">
          <mat-header-cell *matHeaderCellDef> CN Type</mat-header-cell>
          <mat-cell
            *matCellDef="let element"> {{ element.consignmentType ? element?.consignmentType : "All" }}
          </mat-cell>
        </ng-container>

        <!-- CN Subtype Column -->
        <ng-container matColumnDef="cnSubtype">
          <mat-header-cell *matHeaderCellDef> CN Subtype</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{
              element.consignmentSubtype ? element?.consignmentSubtype :
                "All"
            }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="origin">
          <mat-header-cell *matHeaderCellDef> Origin</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{
              element.origin ? element.origin :
                "All"
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="destination">
          <mat-header-cell *matHeaderCellDef> Destination</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{
              element.destination ? element.destination :
                "All"
            }}
          </mat-cell>
        </ng-container>

        <!-- Valid From Column -->
        <ng-container matColumnDef="validFrom">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Valid From</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.startDate | date: 'dd/MM/yyyy' }}</mat-cell>
        </ng-container>

        <!-- Valid Upto Column -->
        <ng-container matColumnDef="validUpto">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Valid Upto</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.endDate | date: 'dd/MM/yyyy' }}</mat-cell>
        </ng-container>

        <!-- Start Time Column -->
        <ng-container matColumnDef="startTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Start Time</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ formatTime(element.startTime) }}</mat-cell>
        </ng-container>

        <!-- End Time Column -->
        <ng-container matColumnDef="endTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header> End Time</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ formatTime(element.endTime) }}</mat-cell>
        </ng-container>


        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <button mat-icon-button color="primary" aria-label="Example icon button with a heart icon"
                    (click)="viewAirlinePromotion(element.id)">
              <mat-icon>visibility</mat-icon>
            </button>
            <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon"
                    (click)="deleteAirlinePromotion(element.id, paginator.pageIndex * paginator.pageSize + i + 1)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>
      <mat-paginator [length]="paginatorDetails?.totalElements" [pageSize]="paginatorDetails?.pageSize"
                     [pageSizeOptions]="[5, 10, 20, 30]" (page)="pageEvent = $event; onPaginateChange($event)">
      </mat-paginator>

<!--    </div>-->

  </ng-container>
  <ng-template #noRecordsMessage>
    <h1 class="text-center ">No Records Available..!</h1>
  </ng-template>


</mat-card>